















































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapState } from "pinia";
import { APPLICATION_VERSION } from "@/lib/constants/env";
import { useLocationStore } from "@/pinia/location";

type Data = {
	loading: boolean;
	APPLICATION_VERSION: typeof APPLICATION_VERSION;
};

export default defineComponent({
	data(): Data {
		return {
			loading: false,
			APPLICATION_VERSION,
		};
	},
	computed: {
		...mapState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
	},
	async created() {
		this.loading = true;

		await this.initLocation();

		this.loading = false;
	},
	methods: {
		...mapActions(useLocationStore, {
			initLocation: "init",
			setLocation: "set",
		}),
		handleLocationChange(payload: Event) {
			this.setLocation(Number((payload.target as HTMLSelectElement)?.value));
		},
	},
});
